.AnalyticsBox {
    .columnContainer {
        display: flex;
        justify-content: space-between;
        padding-bottom: 3rem;
    }

    .column {
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        flex-basis: 50%;

        &.extraGap {
            gap: 1rem 0;
        }

        &:nth-child(even) {
            border-left: 1px solid var(--zero-border-color);
        }

        &:only-child {
            flex-basis: 100%;
        }
    }

    .itemWrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.2rem 0;

        &:hover {
            background-color: rgba(0, 0, 0, 0.015);
        }
    }
}

.SubmissionAnalyticsBlock {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1rem 0;

    & > * {
        width: 14rem;
    }
}