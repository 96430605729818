/* Overriding the Snow theme for the Quill editor */

.ql-snow .ql-editor a {
  color: var(--zero-light-blue);
  text-decoration: underline;
}

.quill-preview {
  margin: 0;

  &.no-p-margin {
    p {
      margin: 0
    }
  }

  p {
    color: var(--zero-dark-grey);
  }

  a {
    color: var(--zero-light-blue);
    text-decoration: underline;
  }
}
